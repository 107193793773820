import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [UserService]
})
export class LoginComponent implements OnInit {
  public page_title: string;
  public user: User;
  public status: string;
  public token;
  public identity;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute
    ) {
    this.page_title = 'Login';
    this.user = new User(1, '', '', 'ROLE_USER', '', '', '', '');
    this.status = '';
    this.token = '';
    this.identity = '';
  }

  ngOnInit(): void {
    this.logout();
  }

  onSubmit(form: any) {
    this._userService.login(this.user).subscribe(
      response => {

        if (response.status == 'success') {
          this.status = response.status;
          this.token = response.token;
          this.identity = response.usuario;

          localStorage.setItem('token', this.token);
          localStorage.setItem('identity', JSON.stringify(this.identity));
          this._router.navigate(['/']);

        } else this.status = 'error';

      },
      error => {
        this.status = 'error';
        console.log(<any>error);
      }
    );


  }

  logout(){
    this._route.params.subscribe(params => {
      let logout = +params['sure'];

      if(logout == 1){
        localStorage.removeItem('identity');
        localStorage.removeItem('token');

        this.identity = '';
        this.token = '';

        this._router.navigate(['/']);
      }
    });
  }

}
