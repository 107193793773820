<div class="col-md-12 mt-3">
    <h1>{{page_title}}</h1>


    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        Datos grabados correctamente.
    </div>
    <div class="alert alert-danger col-md-5 mt-3" *ngIf="status == 'error'">
        Error al guardar los datos, por favor inténtalo más tarde.
    </div>




    <form class="col-md-12 ml-0 pl-0" #publicacionForm="ngForm" (ngSubmit)="onSubmit(publicacionForm)">

        <a class="btn btn-primary m-2" data-bs-toggle="collapse" href="#collapse1" role="button" aria-expanded="false" aria-controls="collapse1">
      Datos principales
    </a>

        <div class="collapse" id="collapse1">
            <div class="row">
                <div class="form-group mb-2 col-sm">
                    <label for="title">Título</label>
                    <input type="text" name="title" class="form-control" #title="ngModel" [(ngModel)]="publicacion.title" required>
                    <small *ngIf="!title.valid && title.touched" class="invalid-feedback
            d-block">
            El título no es válido
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="categoria_id">Categoria</label>
                    <select type="text" name="categoria_id" class="form-control" #categoria="ngModel" [(ngModel)]="publicacion.categoria_id" required>
            <option *ngFor="let categoria of categorias"
              value="{{categoria.id}}">
              {{categoria.categoriaName}}
            </option>
          </select>
                    <small *ngIf="!categoria.valid && categoria.touched" class="invalid-feedback
            d-block">
            El categoría no es válida
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="cliente_id">Cliente</label>
                    <select type="text" name="cliente_id" class="form-control" #cliente="ngModel" [(ngModel)]="publicacion.cliente_id" required>
            <option *ngFor="let cliente of clientes"
              value="{{cliente.id}}">
              {{cliente.surname}}, {{cliente.name}}
            </option>
          </select>
                    <small *ngIf="!cliente.valid && cliente.touched" class="invalid-feedback
            d-block">
            El cliente no es válido
          </small>
                </div>
            </div>

            <div class="form-group mb-2">
                <label for="content">Contenido</label>
                <textarea name="content" class="form-control" #content="ngModel" [(ngModel)]="publicacion.content"></textarea>
                <small *ngIf="!content.valid && content.touched" class="invalid-feedback
          d-block">
          El contenido no es válido
        </small>
            </div>

        </div>


        <a class="btn btn-primary m-2" data-bs-toggle="collapse" href="#collapse2" role="button" aria-expanded="false" aria-controls="collapse2">
      Datos de contacto
    </a>

        <div class="collapse" id="collapse2">
            <div class="row">
                <div class="form-group mb-2 col-sm">
                    <label for="phone">Teléfono</label>
                    <input type="text" name="phone" class="form-control" #phone="ngModel" [(ngModel)]="publicacion.phone">
                    <small *ngIf="!phone.valid && phone.touched" class="invalid-feedback
            d-block">
            El teléfono no es válido
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="phone2">Teléfono</label>
                    <input type="text" name="phone2" class="form-control" #phone2="ngModel" [(ngModel)]="publicacion.phone2">
                    <small *ngIf="!phone2.valid && phone2.touched" class="invalid-feedback
            d-block">
            El teléfono no es válido
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="email">Email</label>
                    <input type="email" name="email" class="form-control" #email="ngModel" [(ngModel)]="publicacion.email">
                    <small *ngIf="!email.valid && email.touched" class="invalid-feedback
            d-block">
            El email no es válido
          </small>
                </div>

            </div>

            <div class="row">
                <div class="form-group mb-2 col-sm">
                    <label for="location">Dirección</label>
                    <input type="text" name="location" class="form-control" #location="ngModel" [(ngModel)]="publicacion.location">
                    <small *ngIf="!location.valid && location.touched" class="invalid-feedback
            d-block">
            La dirección no es válida
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="map">Coordenadas</label>
                    <input type="text" name="map" class="form-control" #map="ngModel" [(ngModel)]="publicacion.map">
                    <small *ngIf="!map.valid && map.touched" class="invalid-feedback
            d-block">
            Las coordenadas no son válidas
          </small>
                </div>

            </div>


        </div>


        <a class="btn btn-primary m-2" data-bs-toggle="collapse" href="#collapse3" role="button" aria-expanded="false" aria-controls="collapse3">
      Redes sociales
    </a>

        <div class="collapse" id="collapse3">
            <div class="row">
                <div class="form-group mb-2 col-sm">
                    <label for="facebook">Facebook</label>
                    <input type="text" name="facebook" class="form-control" #facebook="ngModel" [(ngModel)]="publicacion.facebook">
                    <small *ngIf="!facebook.valid && facebook.touched" class="invalid-feedback
            d-block">
            Dato inválido
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="telegram">Telegram</label>
                    <input type="text" name="telegram" class="form-control" #telegram="ngModel" [(ngModel)]="publicacion.telegram">
                    <small *ngIf="!telegram.valid && telegram.touched" class="invalid-feedback
            d-block">
            Dato inválido
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="instagram">Instagram</label>
                    <input type="text" name="instagram" class="form-control" #instagram="ngModel" [(ngModel)]="publicacion.instagram">
                    <small *ngIf="!instagram.valid && instagram.touched" class="invalid-feedback
            d-block">
            Dato inválido
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="twitter">Twitter</label>
                    <input type="text" name="twitter" class="form-control" #twitter="ngModel" [(ngModel)]="publicacion.twitter">
                    <small *ngIf="!twitter.valid && twitter.touched" class="invalid-feedback
            d-block">
            Dato inválido
          </small>
                </div>
            </div>


            <div class="row">
                <div class="form-group mb-2 col-sm">
                    <label for="linkedin">Linkedin</label>
                    <input type="text" name="linkedin" class="form-control" #linkedin="ngModel" [(ngModel)]="publicacion.linkedin">
                    <small *ngIf="!linkedin.valid && linkedin.touched" class="invalid-feedback
            d-block">
            Dato inválido
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="whatsapp">Whatsapp</label>
                    <input type="text" name="whatsapp" class="form-control" #whatsapp="ngModel" [(ngModel)]="publicacion.whatsapp">
                    <small *ngIf="!whatsapp.valid && whatsapp.touched" class="invalid-feedback
            d-block">
            Dato inválido
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="youtube">Youtube</label>
                    <input type="text" name="youtube" class="form-control" #youtube="ngModel" [(ngModel)]="publicacion.youtube">
                    <small *ngIf="!youtube.valid && youtube.touched" class="invalid-feedback
            d-block">
            Dato inválido
          </small>
                </div>

                <div class="form-group mb-2 col-sm">
                    <label for="pinterest">Pinterest</label>
                    <input type="text" name="pinterest" class="form-control" #pinterest="ngModel" [(ngModel)]="publicacion.pinterest">
                    <small *ngIf="!pinterest.valid && pinterest.touched" class="invalid-feedback
            d-block">
            Dato inválido
          </small>
                </div>
            </div>
        </div>
        <a class="btn btn-primary m-2" data-bs-toggle="collapse" href="#collapse4" role="button" aria-expanded="false" aria-controls="collapse4">
      Horarios
    </a>
        <div class="collapse" id="collapse4">
            <div class="row">
                <div class="form-group mb-2 col-sm horarios">
                    <label>Lunes: </label>
                    <input type="text" name="lunesD1" class="form-control mt1" [(ngModel)]="horarios.lunesD1" placeholder="desde">
                    <input type="text" name="lunesH1" class="form-control mt-1" [(ngModel)]="horarios.lunesH1" placeholder="hasta">
                    <label> - </label>
                    <input type="text" name="lunesD2" class="form-control mt1" [(ngModel)]="horarios.lunesD2" placeholder="desde">
                    <input type="text" name="lunesH2" class="form-control mt-1" [(ngModel)]="horarios.lunesH2" placeholder="hasta">
                </div>
                <div class="form-group mb-2 col-sm horarios">
                    <label>Martes: </label>
                    <input type="text" name="martesD1" class="form-control mt1" [(ngModel)]="horarios.martesD1" placeholder="desde">
                    <input type="text" name="martesH1" class="form-control mt-1" [(ngModel)]="horarios.martesH1" placeholder="hasta">
                    <label> - </label>
                    <input type="text" name="martesD2" class="form-control mt1" [(ngModel)]="horarios.martesD2" placeholder="desde">
                    <input type="text" name="martesH2" class="form-control mt-1" [(ngModel)]="horarios.martesH2" placeholder="hasta">
                </div>
                <div class="form-group mb-2 col-sm horarios">
                    <label>Miercoles: </label>
                    <input type="text" name="miercolesD1" class="form-control mt1" [(ngModel)]="horarios.miercolesD1" placeholder="desde">
                    <input type="text" name="miercolesH1" class="form-control mt-1" [(ngModel)]="horarios.miercolesH1" placeholder="hasta">
                    <label> - </label>
                    <input type="text" name="miercolesD2" class="form-control mt1" [(ngModel)]="horarios.miercolesD2" placeholder="desde">
                    <input type="text" name="miercolesH2" class="form-control mt-1" [(ngModel)]="horarios.miercolesH2" placeholder="hasta">
                </div>
                <div class="form-group mb-2 col-sm horarios">
                    <label>Jueves: </label>
                    <input type="text" name="juevesD1" class="form-control mt1" [(ngModel)]="horarios.juevesD1" placeholder="desde">
                    <input type="text" name="juevesH1" class="form-control mt-1" [(ngModel)]="horarios.juevesH1" placeholder="hasta">
                    <label> - </label>
                    <input type="text" name="juevesD2" class="form-control mt1" [(ngModel)]="horarios.juevesD2" placeholder="desde">
                    <input type="text" name="juevesH2" class="form-control mt-1" [(ngModel)]="horarios.juevesH2" placeholder="hasta">
                </div>
                <div class="form-group mb-2 col-sm horarios">
                    <label>Viernes: </label>
                    <input type="text" name="viernesD1" class="form-control mt1" [(ngModel)]="horarios.viernesD1" placeholder="desde">
                    <input type="text" name="viernesH1" class="form-control mt-1" [(ngModel)]="horarios.viernesH1" placeholder="hasta">
                    <label> - </label>
                    <input type="text" name="viernesD2" class="form-control mt1" [(ngModel)]="horarios.viernesD2" placeholder="desde">
                    <input type="text" name="viernesH2" class="form-control mt-1" [(ngModel)]="horarios.viernesH2" placeholder="hasta">
                </div>
                <div class="form-group mb-2 col-sm horarios">
                    <label>Sábado: </label>
                    <input type="text" name="sabadoD1" class="form-control mt1" [(ngModel)]="horarios.sabadoD1" placeholder="desde">
                    <input type="text" name="sabadoH1" class="form-control mt-1" [(ngModel)]="horarios.sabadoH1" placeholder="hasta">
                    <label> - </label>
                    <input type="text" name="sabadoD2" class="form-control mt1" [(ngModel)]="horarios.sabadoD2" placeholder="desde">
                    <input type="text" name="sabadoH2" class="form-control mt-1" [(ngModel)]="horarios.sabadoH2" placeholder="hasta">
                </div>
                <div class="form-group mb-2 col-sm horarios">
                    <label>Domingo: </label>
                    <input type="text" name="domingoD1" class="form-control mt1" [(ngModel)]="horarios.domingoD1" placeholder="desde">
                    <input type="text" name="domingoH1" class="form-control mt-1" [(ngModel)]="horarios.domingoH1" placeholder="hasta">
                    <label> - </label>
                    <input type="text" name="domingoD2" class="form-control mt1" [(ngModel)]="horarios.domingoD2" placeholder="desde">
                    <input type="text" name="domingoH2" class="form-control mt-1" [(ngModel)]="horarios.domingoH2" placeholder="hasta">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm">
                <input type="submit" value="Guardar" class="btn btn-success mt-2" [disabled]="publicacionForm.invalid">
            </div>
        </div>
    </form>
</div>
