import { Component, OnInit } from '@angular/core';
import { CategoriaService } from 'src/app/services/categoria.sevice';


@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [CategoriaService]
})
export class CarouselComponent implements OnInit {
  public categorias: any;
  public isWait: boolean = true;

  constructor(private _categoriaService: CategoriaService) {
    this.categorias = null;
  }

  ngOnInit(): void {
    this.getcategorias();
  }

  // Se obtienen todas las categorías
  getcategorias() {
    this._categoriaService.getCategorias().subscribe(
      response => {
        if (response.status == 'success') {
          this.categorias = response.categorias;

          for (let index = 0; index < this.categorias.length; index++) {
            this.categorias[index].url = this.eliminarDiacriticosEs(this.categorias[index].categoriaName);

          }
          this.isWait = false;

        }
      },
      error => {
        console.log(error);
        this.isWait = false;
      }
    )
  }

  eliminarDiacriticosEs(str: String) {
    for (var i = 0; i < str.length; i++) {
      //Sustituye "á é í ó ú"
      if (str.charAt(i) == "á") str = str.replace(/á/, "a");
      if (str.charAt(i) == "é") str = str.replace(/é/, "e");
      if (str.charAt(i) == "í") str = str.replace(/í/, "i");
      if (str.charAt(i) == "ó") str = str.replace(/ó/, "o");
      if (str.charAt(i) == "ú") str = str.replace(/ú/, "u");
    }
    return str;
  }

}
