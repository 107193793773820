import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Categoria } from '../models/categoria';
import { global } from './global';

@Injectable()
export class CategoriaService {
  public url: string;
  public token: any;
  public identity: any;

  constructor(public _http: HttpClient

  ) {
    this.url = global.url;
    this.token = null;
    this.identity = null;
  }


  create(token: string, categoria: Categoria): Observable<any> {

    let json = JSON.stringify(categoria);
    let params = 'json=' + json;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.post(this.url + 'category', params, { headers: headers });

  }

  getCategorias(): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'category', { headers: headers });
  }

  getCategoria(id: number): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'category/' + id, { headers: headers });
  }

  update(token: string, categoria: Categoria, id: number): Observable<any> {

    let json = JSON.stringify(categoria);
    let params = 'json=' + json;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.put(this.url + 'category/' + id, params, { headers: headers });
  }

  delete(token: string, id: number) {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);
    return this._http.delete(this.url + 'category/' + id, { headers: headers })
  }

}
