import { Component, OnInit, Input } from '@angular/core';
import { Emergencia } from '../../models/emergencia';
import { Meta } from '@angular/platform-browser';

// Importo el archivo JSON
import listaDeEmergencias from 'src/assets/json/emergencias.json';

import { FuncionesService } from '../../services/funciones.service';

@Component({
  selector: 'app-emergencias',
  templateUrl: './emergencias.component.html',
  styleUrls: ['./emergencias.component.scss'],
  providers: [FuncionesService]
})
export class EmergenciasComponent implements OnInit {

  private listaEmergencias: any = listaDeEmergencias;
  public page_title: string;
  public bomberos: Emergencia[];
  public comisarias: Emergencia[];
  public hospitales: Emergencia[];
  public loading: boolean;

  constructor(
    private meta: Meta,
    private _funcionesService: FuncionesService) {

    this.page_title = "Emergencias en Laferrere";
    this.loading = true;
    this.hospitales = [];
    this.bomberos = [];
    this.comisarias = [];

    this.separarEmergencias();
  }

  ngOnInit(): void {
    this._funcionesService.setTitle('enLaferrere | Emergencias');

    this.meta.updateTag({ name: 'description', content: 'Emergencias en Lafererre' }, "name='description'");
    this.meta.updateTag({ name: 'keywords', content: 'hospitales, comisarías, bomberos, Laferrere' }, "name='keywords'");

  }

//las emergencias se separan por categorías
  separarEmergencias(){

    for (let element of this.listaEmergencias){
      if (element.grupo.includes("Bomberos")) this.bomberos.push(element);
      else if (element.grupo.includes("Comisarías"))
        this.comisarias.push(element);
      else this.hospitales.push(element);

          this.loading = false;
    }

  }

}
