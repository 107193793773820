import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { UserService } from '../../../services/user.service';
import { CategoriaService } from '../../../services/categoria.sevice';
import { Categoria } from '../../../models/categoria';


@Component({
  selector: 'app-categoria-new',
  templateUrl: './categoria-new.component.html',
  styleUrls: ['./categoria-new.component.scss'],
  providers: [UserService, CategoriaService]
})
export class CategoriaNewComponent implements OnInit {
  public page_title: string;
  public identity: any;
  public token: any;
  public categoria: Categoria;
  public status: string;

  constructor(
    private _userService: UserService,
    private _categoriaService: CategoriaService,
    private _router: Router,

  ) {
    this.page_title = 'Crear nueva categoría';
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.categoria = new Categoria(1,'','');
    this.status = '';
  }

  ngOnInit(): void {
  }


  onSubmit(form: any) {
    this._categoriaService.create(this.token, this.categoria).subscribe(
      response => {
        if (response.status == 'success') {
          this.status = response.status;
          this.categoria = response.categoria;
          this._router.navigate(['/categorias']);
        } else this.status = 'error';

      },
      error => {
        this.status = 'error';
        console.log(<any>error);
      }
    );


  }

}
