<section>

    <div class="d-flex justify-content-between">
        <h4>Categorías</h4>
        <a routerLink="/crear-categoria/" class="btn btn-success">Crear nueva categoría</a>
    </div>

    <br>
    <div class="content">
        <table class="table ">
            <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>imagen</th>
                <th width="220px">Acciones</th>
            </tr>
            <tr *ngFor="let item of categorias">
                <td>{{ item.id }}</td>
                <td>{{ item.categoriaName }}</td>
                <td>{{ item.iconImage }}</td>
                <td>
                    <a href="#" [routerLink]="['/editar-categoria/', item.id]" class="btn btn-outline-primary">Modificar</a>
                    <button type="button" (click)="deleteCategoria(item.id)" class="btn btn-outline-danger">Eliminar</button>
                </td>
            </tr>
        </table>
    </div>





</section>
