<div class="col-md-12 mt-3">
    <h1>{{page_title}}</h1>

    <div class="alert alert-danger col-md-5 mt-3" *ngIf="status == 'error'">
        Datos Incorrectos.
    </div>

    <form class="col-md-5 ml-0 pl-0" #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">

        <div class="form-group mb-2">
            <label for="name">Email</label>
            <input type="email" name="email" class="form-control" #email="ngModel" [(ngModel)]="user.email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">
              El Email no es válido
            </small>
        </div>
        <div class="form-group mb-2">
            <label for="password">Contraseña</label>
            <input type="password" name="password" class="form-control" #password="ngModel" [(ngModel)]="user.password" required>
            <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">
              La contraseña no es válida
            </small>
        </div>

        <input type="submit" value="Entrar" class="btn btn-success mt-2" [disabled]="loginForm.invalid">
    </form>
</div>
