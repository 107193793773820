import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';


import { FuncionesService } from '../../services/funciones.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
  providers: [FuncionesService]
})
export class InicioComponent implements OnInit {


  constructor(
    private meta: Meta,
    private _funcionesService: FuncionesService
  ) {

  }

  ngOnInit(): void {
    this._funcionesService.setTitle('enLaferrere');

    this.meta.updateTag({
      name: 'description', content: 'Todo lo que necesitás en Lafererre'
    }, "name='description'");
    this.meta.updateTag({ name: 'keywords', content: 'hospitales, farmacias, comercios, servicios, Laferrer' }, "name='keywords'");


  }


}
