<section>

    <h6 class="text-center mt-2">
        Puedes escribir arriba lo que etás buscando, o seleccionar las categorias que están debajo.</h6>
    <mat-progress-spinner mode="indeterminate" diameter="70" strokeWidth="10" *ngIf="isWait" class="center"></mat-progress-spinner>



    <carousel [loop]="true" [autoplay]="true" [dots]="false" *ngIf="!isWait && categorias.length > 0">

        <div class="carousel-cell" [routerLink]="['/categorias/'+item.id]" class="carousel-cell" *ngFor="let item of categorias; index as i">

            <img *ngIf="item.iconImage" [src]="'assets/images/' + item.iconImage +'-img.jpg'" />
            <img *ngIf="!item.iconImage" [src]="'assets/images/categorias-img.jpg'" />

            <h4 class="text-center">{{item.categoriaName}}</h4>

        </div>
    </carousel>

</section>
