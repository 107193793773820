import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publicacion } from '../models/publicacion';
import { global } from './global';

@Injectable()
export class PublicacionService {
  public url: string;
  public token: any;
  public identity: any;

  constructor(public _http: HttpClient

  ) {
    this.url = global.url;
    this.token = null;
    this.identity = null;
  }


  create(token: string, publicacion: Publicacion): Observable<any> {

    let json = JSON.stringify(publicacion);
    let params = 'json=' + json;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);
    console.log(params)
    return this._http.post(this.url + 'post', params, { headers: headers });

  }

  getCategorias(): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'category', { headers: headers });
  }

  getClientes(): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'client', { headers: headers });
  }

  getPublicaciones(): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'post', { headers: headers });
  }

  getPublicacionesByCategoria(id:number): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'post/category/'+id, { headers: headers });
  }

  getPublicacion(id: number): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'post/' + id, { headers: headers });
  }

  update(token: string, publicacion: Publicacion, id: number): Observable<any> {

    let json = JSON.stringify(publicacion);
    let params = 'json=' + json;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);
    return this._http.put(this.url + 'post/' + id, params, { headers: headers });
  }

  delete(token: string, id: number) {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);
    return this._http.delete(this.url + 'post/' + id, { headers: headers })
  }

}
