<section>

    <div class="d-flex justify-content-between">
        <h4>Publicaciones</h4>
        <a routerLink="/crear-publicacion/" class="btn btn-success">
          Crear nueva publicación
        </a>
    </div>

    <br>
    <div class="content">
        <table class="table ">
            <tr>
                <th>ID</th>
                <th>Cliente</th>
                <th>Categoria</th>
                <th>Título</th>
                <th>Estado</th>
                <th>Contenido</th>
                <th width="220px">Acciones</th>
            </tr>
            <tr *ngFor="let item of publicaciones">
                <td>{{ item.id }}</td>
                <td>{{ item.cliente_id }}</td>
                <td>{{ item.categoria_id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.enabled }}</td>
                <td>{{ item.content }}</td>
                <td>
                    <a href="#" [routerLink]="['/editar-publicacion', item.id  ]" class="btn btn-outline-primary">Modificar</a>
                    <button type="button" (click)="deletePublicacion(item.id)" class="btn btn-outline-danger">Eliminar</button>
                </td>
            </tr>
        </table>
    </div>
</section>
