import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClienteService } from '../../../services/cliente.service';
import { UserService } from '../../../services/user.service';
import { Cliente } from '../../../models/cliente';

@Component({
  selector: 'app-cliente-edit',
  templateUrl: '../cliente-new/cliente-new.component.html',
  styleUrls: ['../cliente-new/cliente-new.component.scss'],
  providers: [UserService, ClienteService]
})
export class ClienteEditComponent implements OnInit {
  public page_title: string;
  public identity: any;
  public token: any;
  public cliente: Cliente;
  public status: string;

  constructor(
    private _userService: UserService,
    private _clienteService: ClienteService,
    private _router: Router,
    private _route: ActivatedRoute,

  ) {
    this.page_title = 'Editar cliente';
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.cliente = new Cliente(1, '', '', '', '', '', '', '', '', '', '');
    this.status = '';
  }

  ngOnInit(): void {
    this.getCliente();
  }

  onSubmit(form: any) {
    this._clienteService.update(this.token, this.cliente, this.cliente.id).subscribe(
      response => {
        if (response.status == 'success') {
          this.status = response.status;
          this.cliente = response.cliente;
          this._router.navigate(['/clientes']);
        } else this.status = 'error';

      },
      error => {
        this.status = 'error';
        console.log(<any>error);
      }
    );


  }

  getCliente(){

    this._route.params.subscribe({
      next: (params) => {
        let id = +[params['id']];

        this._clienteService.getCliente(id).subscribe(
          response => {
            if (response.status == 'success') {
              this.cliente = response.cliente;
            }
          },
          error => {
            console.log(error);
          }
        )
      },
      complete: () => { },
      error: (error) => { console.log(error);}
    });

  }

}
