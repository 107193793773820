<div class="col-md-12 mt-3">
    <h1>{{page_title}}</h1>

    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        El registro se ha completado correctamente, <a [routerLink]="['/login']">loguéate aquí</a>.
    </div>
    <div class="alert alert-danger col-md-5 mt-3" *ngIf="status == 'error'">
        El registro no se ha completado, por favor inténtalo más tarde.
    </div>

    <form class="col-md-5 ml-0 pl-0" #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm)">
        <div class="form-group mb-2">
            <label for="name">Nombre</label>
            <input type="text" name="name" class="form-control" #name="ngModel" [(ngModel)]="user.name" required pattern="[a-zA-ZÀ-ÿ\u00f1\u00d1]+">
            <small *ngIf="!name.valid && name.touched" class="invalid-feedback d-block">
              El nombre no es válido
            </small>
        </div>
        <div class="form-group mb-2">
            <label for="surname">Apellido</label>
            <input type="text" name="surname" class="form-control" #surname="ngModel" [(ngModel)]="user.surname" required pattern="[a-zA-ZÀ-ÿ\u00f1\u00d1]+">
            <small *ngIf="!surname.valid && surname.touched" class="invalid-feedback d-block">
              El Apellido no es válido
            </small>
        </div>
        <div class="form-group mb-2">
            <label for="name">Email</label>
            <input type="email" name="email" class="form-control" #email="ngModel" [(ngModel)]="user.email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
            <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">
              El Email no es válido
            </small>
        </div>
        <div class="form-group mb-2">
            <label for="password">Contraseña</label>
            <input type="password" name="password" class="form-control" #password="ngModel" [(ngModel)]="user.password" required>
            <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">
              La contraseña no es válida
            </small>
        </div>

        <input type="submit" value="Registrarme" class="btn btn-success mt-2" [disabled]="registerForm.invalid">
    </form>
</div>
