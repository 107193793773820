import { Component, OnInit } from '@angular/core';
import { PublicacionService } from '../../services/publicacion.sevice';
import { CategoriaService } from '../../services/categoria.sevice';
import { Publicacion } from '../../models/publicacion';
import { Categoria } from '../../models/categoria';
import { Horario } from 'src/app/models/horario';


@Component({
  selector: 'app-buscador',
  templateUrl: './buscador.component.html',
  styleUrls: ['./buscador.component.scss'],
  providers: [PublicacionService, CategoriaService]
})


export class BuscadorComponent implements OnInit {
  public publicaciones: Publicacion[] = [];
  public publicacion: Publicacion;
  public searchPublicaciones: string;
  public mostrarCard: boolean = false;
  public horarios: Horario;
  public categoria: Categoria;

  constructor(
    private _publicacioService: PublicacionService,
    private _categoriaService: CategoriaService
    ){
    this.searchPublicaciones = " ";
    this.publicacion = new Publicacion(1, 1, 1, ' ', ' ', ' ', '', '', '', '', 1, '', '', '', '', '', '', '', '', '', true);
    this.horarios = new Horario('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
    this.categoria = new Categoria(1, '', '');
  }


  ngOnInit(): void {
    this.getPublicaciones();
  }


  getPublicaciones() {
    this._publicacioService.getPublicaciones().subscribe(
      response => {
        if (response.status == 'success') {
          this.publicaciones = response.publicaciones;


        }
      },
      error => {
        console.log(error);
      }
    )
  }

  // se muestra la publicación seleccionada
  showPublicacion(publicacion: Publicacion) {
    this.publicacion = publicacion;
    console.log(this.publicacion)
    if (this.publicacion.title) {
      this.searchPublicaciones = "";
      this.mostrarCard = true;
      this.getCategoria(this.publicacion.categoria_id);

      this.horarios = JSON.parse(publicacion.schedule);

    }
  }


  getCategoria(id:number) {

        this._categoriaService.getCategoria(id).subscribe(
          response => {

            if (response.status == 'success') {
              this.categoria = response.categoria;

            }
          },
          error => {
            console.log(error);
          }
        )


  }


}
