<h1 class="text-center fs-3 mt-2">{{page_title}}</h1>

<tarjeta-emergencias 
    [emergencias]="bomberos" 
    [categoria]="'Bomberos'" 
    [imagen]="'bomberos.png'">
</tarjeta-emergencias>

<tarjeta-emergencias 
    [emergencias]="comisarias" 
    [categoria]="'Comisarías'" 
    [imagen]="'comisarias.png'">
</tarjeta-emergencias>

<tarjeta-emergencias 
    [emergencias]="hospitales" 
    [categoria]="'Hospitales'" 
    [imagen]="'hospitales.png'">
</tarjeta-emergencias>


