import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PublicacionService } from '../../../services/publicacion.sevice';
import { UserService } from '../../../services/user.service';
import { Publicacion } from '../../../models/publicacion';

@Component({
  selector: 'app-publicacion',
  templateUrl: './publicacion.component.html',
  styleUrls: ['./publicacion.component.scss'],
  providers: [UserService, PublicacionService]
})
export class PublicacionComponent implements OnInit {
  publicaciones: Publicacion[] = [];
  public token: string;

  constructor(
    private _publicacioService: PublicacionService,
    private _userService: UserService) {
    this.token = this._userService.getToken();
  }

  ngOnInit(): void {
    this.getPublicaciones();
  }

  getPublicaciones() {
    this._publicacioService.getPublicaciones().subscribe(
      response => {
        if (response.status == 'success') {
          this.publicaciones = response.publicaciones;


        }
      },
      error => {
        console.log(error);
      }
    )
  }

  deletePublicacion(id: number) {
    if (confirm("Se eliminará la publicación " + id))
      this._publicacioService.delete(this.token, id).subscribe(
        response => {
          this.getPublicaciones();

        },
        error => {
          console.log(error);
        }
      )
  }


}
