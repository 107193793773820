<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">

        <!-- LOGO -->
        <a class="navbar-brand" href="#">
            <img src="assets/icons/icon-512x512.png" height="30" width="30" class="d-inline-block align-top" alt="logo" />
        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-link" aria-current="page" [routerLink]="['/']" routerLinkActive="router-link-active">Inicio</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/emergencias']" routerLinkActive="router-link-active">Emergencias</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/farmacias']" routerLinkActive="router-link-active">Farmacias de turno</a>
                </li>
            </ul>

            <ul class="navbar-nav navbar-right" *ngIf="identity && identity.name">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{identity.name}}
          </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" [routerLink]="['/categorias']">
              Categorías
            </a>
                        <a class="dropdown-item" [routerLink]="['/clientes']">
              Clientes
            </a>
                        <a class="dropdown-item" [routerLink]="['/publicaciones']">
              Publicaciones
            </a>
                        <a class="dropdown-item" [routerLink]="['/logout/1']">
              Cerrar sesión
            </a>
                    </div>
                </li>




            </ul>
            <ul class="navbar-nav navbar-right">
                <li class="nav-item cta-menu" *ngIf="showButton">
                    <a mat-raised-button color="accent" (click)="addToHomeScreen()">Instalar
            App</a>
                </li>

            </ul>



            <!--
      <form class="d-flex ">
        <input class="form-control me-2 " type="search " placeholder="Search " aria-label="Search ">
        <button class="btn btn-outline-success " type="submit ">Search</button>
      </form>
      -->
        </div>
    </div>
</nav>
<div class="container">
    <router-outlet></router-outlet>
</div>


<footer>
    <br />
    <hr>

    <h3 class="text-center h6">
        Si tenés un comercio o brindás algún servicio que quieras publicar escribinos a
        <a href="mailto:info@enlaferrere.com.ar">
      info@enlaferrere.com.ar</a>
    </h3>
    <hr>
</footer>
