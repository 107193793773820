import { Pipe, PipeTransform } from '@angular/core';
import { Publicacion } from '../models/publicacion';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any[], args: any): Publicacion[] {

    const resultado = [];

    if (args.length > 2)
      for (const publicaciones of value) {
        if (publicaciones.title.indexOf(args) > -1) {
          resultado.push(publicaciones);
        }
      }
    return resultado;
  }

}
