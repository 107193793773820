    <div class="card border-warning bg-light mb-3">
      <div class="card-header">
        <h2 class="h3 text-center">{{ categoria }}</h2>
        <img *ngIf="imagen"
          src="{{ 'assets/images/' + imagen }}" alt="{{imagen}}"
        />
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item border-warning" *ngFor="let emergencia of emergencias" >
          <div class="card-body">
            <h3 class="card-title text-center h4">{{ emergencia.nombre }}</h3>

            <!--    Datos de contacto   -->
            <dl class="card-text">
              <div class="card-text-item"  *ngIf="emergencia.telefono1">
              <dt class="text-center" >
                Teléfono
              </dt>

                  <dd class="text-center">
                    {{ emergencia.telefono1 }}
                  </dd>
                  <dd class="text-center">
                    <button 
                      (click)="goToTel(emergencia.telefono1)"
                      class="btn btn-primary position-relative"> 
                      LLamar <i class="fa fa-mobile"></i>
                    </button>
       
              </dd>
            </div>

            <div class="card-text-item"  *ngIf="emergencia.telefono2">
              <dt class="text-center" >
                Teléfono
              </dt>

                  <dd class="text-center">
                    {{ emergencia.telefono2 }}
                  </dd>
                  <dd class="text-center">
                    <button 
                      (click)="goToTel(emergencia.telefono2)"
                      class="btn btn-primary position-relative"> 
                      LLamar <i class="fa fa-mobile"></i>
                    </button>
       
              </dd>
            </div>

              <!-- Ubicación -->
              <div class="card-text-item">
              <dt class="text-center" *ngIf="emergencia.direccion">
                Dirección
              </dt>
        
      
                  <dd class="text-center">
                    {{  emergencia.direccion }}
                  </dd>
                  <dd class="text-center">
                    <button 
                      (click)="goToDireccion(emergencia.map ? emergencia.map : emergencia.direccion, emergencia.map)"
                      class="btn btn-primary position-relative"> 
                      Cómo llegar <i class="fa fa-map-marker"></i>
                    </button>
                  </dd>
            
         
            </div>
            </dl>
          </div>


        </li>
      </ul>
    </div>
