import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteService } from '../../../services/cliente.service';
import { UserService } from '../../../services/user.service';
import { Cliente } from '../../../models/cliente';

@Component({
  selector: 'app-cliente-new',
  templateUrl: './cliente-new.component.html',
  styleUrls: ['./cliente-new.component.scss'],
  providers: [UserService, ClienteService]
})
export class ClienteNewComponent implements OnInit {
  public page_title: string;
  public identity: any;
  public token: any;
  public cliente: Cliente;
  public status: string;

  constructor(
    private _userService: UserService,
    private _clienteService: ClienteService,
    private _router: Router,

  ) {
    this.page_title = 'Crear nuevo cliente';
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.cliente = new Cliente(1, '', '', '', '', '', '', '', '', '','');
    this.status = '';
  }

  ngOnInit(): void {
  }

  onSubmit(form: any) {
    this._clienteService.create(this.token, this.cliente).subscribe(
      response => {
        if (response.status == 'success') {
          this.status = response.status;
          this.cliente = response.cliente;
          this._router.navigate(['/clientes']);
        } else this.status = 'error';

      },
      error => {
        this.status = 'error';
        console.log(<any>error);
      }
    );


  }

}
