<div class="search-box">
    <input placeholder="¿Qué estás buscando?" class="form-control mr-sm-2 search-input" name="searchPublicaciones" [(ngModel)]="searchPublicaciones" />

    <img src="assets/images/search_icon.png" alt="buscador" class="search-icon" />
</div>
<ul class="result-list">
    <li *ngFor="let publicacion of publicaciones | search:searchPublicaciones" class="result-item">
        <a class="result-link" (click)="showPublicacion(publicacion)">
            <div class="result-title">{{ publicacion.title }}</div>
            <div class="result-content">
                {{ publicacion.content.substr(0, 80) }}
            </div>
        </a>
    </li>
</ul>

<app-tarjeta-publicacion *ngIf="mostrarCard" [publicacion]="publicacion" [categoria]="categoria" [horarios]="horarios"></app-tarjeta-publicacion>
