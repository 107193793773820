  <section class="emergencias">
    <div [routerLink]="['/emergencias']"  class="card card-imagenes">
      <img src="assets/images/emergencias-img.jpg" alt="emergencias" />
      <div class="card-body">
        <h4 class="card-title text-center">Emergencias</h4>

      </div>
    </div>

    <div [routerLink]="['/farmacias']" class="card card-imagenes">
      <img src="assets/images/farmacias-img.jpg" alt="farmacias" />
      <div class="card-body">
        <h4 class="card-title text-center">Farmacias de Turno</h4>

      </div>
    </div>
  </section>
