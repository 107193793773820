import { Component, OnInit } from '@angular/core';
import { CategoriaService } from '../../../services/categoria.sevice';
import { Categoria } from '../../../models/categoria';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss'],
  providers: [CategoriaService, UserService]
})
export class CategoriaComponent implements OnInit {
  public token: string;

  categorias: Categoria[] = [];
  constructor(
    private _categoriaService: CategoriaService,
    private _userService: UserService
    ) {
    this.token = this._userService.getToken();
    }

  ngOnInit(): void {
    this.getCategorias();
  }

  // Se obtienen todas las categorías
  getCategorias() {
    this._categoriaService.getCategorias().subscribe(
      response => {
        if (response.status == 'success') {
          this.categorias = response.categorias;


        }
      },
      error => {
        console.log(error);
      }
    )
  }

  deleteCategoria(id: number){
    if (confirm("Se eliminará la categoría " + id))
      this._categoriaService.delete(this.token, id).subscribe(
        response => {
          this.getCategorias();

        },
        error => {
          console.log(error);
        }
      )
  }

}
