export class Publicacion {
  constructor(
    public id: number,
    public cliente_id: number,
    public categoria_id: number,
    public title: string,
    public content: string,
    public phone: string,
    public phone2: string,
    public location: string,
    public map: string,
    public email: string,
    public views: number,
    public schedule: string,
    public facebook: string,
    public whatsapp: string,
    public instagram: string,
    public twitter: string,
    public linkedin: string,
    public youtube: string,
    public telegram: string,
    public pinterest: string,
    public enabled: boolean
  ) { }
}
