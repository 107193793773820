import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { FuncionesService } from '../../services/funciones.service';

// Importo el archivo JSON
import listaDeFarmacias from 'src/assets/json/farmacias2022.json';

@Component({
  selector: 'app-farmacias',
  templateUrl: './farmacias.component.html',
  styleUrls: ['./farmacias.component.scss'],
  providers: [FuncionesService]
})
export class FarmaciasComponent implements OnInit {
  public selected: Date;  // fecha seleccionada
  public page_title: string;
  private listaFarmacias: any = listaDeFarmacias; //obtengo las farmacias del JSON
  public farmacias: any;
  private initDate: Date;

  constructor(
    private _funcionesService: FuncionesService,
    private meta: Meta) {

    this.selected = new Date();

    this.initDate = new Date(2021, 8, 27); //fecha que se toma como inicio para el listado de farmacias
    this.page_title = "Farmacias de turno en Laferrere";

    this.fechaSeleccionada(this.selected);
   }




  ngOnInit(): void {
    this._funcionesService.setTitle('enLaferrere | Farmacias');

    this.meta.updateTag({ name: 'description', content: 'Farmacias de turno en Lafererre' }, "name='description'");
    this.meta.updateTag({ name: 'keywords', content: 'turno, farmacias, Laferrere' }, "name='keywords'");
  }

  goToDireccion(location:string): void {
    this._funcionesService.goToLocation(location + '+Gregorio+de+Laferrere+Provincia+de+Buenos+Aires');
  }

// se muestran las farmacias según la fecha seleccionada
  fechaSeleccionada(e:any) {
      this.farmacias = [];
      this.selected = e;

      if (e >= this.initDate) {
        let difference = Math.abs(<any>e - <any>this.initDate);
        let days = Math.trunc(difference / (1000 * 3600 * 24)) % 10;

        for (let farmacia of this.listaFarmacias){

          if(farmacia.grupo == days) this.farmacias.push(farmacia);
        }

      }
    }


}
