export class Cliente {
  constructor(
    public id: number,
    public name: string,
    public email: string,
    public surname: string,
    public phone: string,
    public direction: string,
    public description: string,
    public image: string,
    public document_type: string,
    public document_number: string,
    public razon_social: string
  ) { }
}
