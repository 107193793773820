import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InicioComponent } from './components/inicio/inicio.component';
import { EmergenciasComponent } from './components/emergencias/emergencias.component';
import { FarmaciasComponent } from './components/farmacias/farmacias.component';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { ErrorComponent } from './components/error/error.component';

import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';

import { ClienteComponent } from './components/admin/cliente/cliente.component';
import { ClienteNewComponent } from './components/admin/cliente-new/cliente-new.component';
import { ClienteEditComponent } from './components/admin/cliente-edit/cliente-edit.component';

import { CategoriaComponent } from './components/admin/categoria/categoria.component';
import { CategoriaNewComponent } from './components/admin/categoria-new/categoria-new.component';
import { CategoriaEditComponent } from './components/admin/categoria-edit/categoria-edit.component';

import { PublicacionComponent } from './components/admin/publicacion/publicacion.component';
import { PublicacionNewComponent } from './components/admin/publicacion-new/publicacion-new.component';
import { PublicacionEditComponent } from './components/admin/publicacion-edit/publicacion-edit.component';

import { IdentityGuard } from './services/identity.guard';


const appRoutes: Routes = [
    {path: '', component: InicioComponent},
    {path: 'emergencias', component: EmergenciasComponent},
    {path: 'farmacias', component: FarmaciasComponent},
    { path: 'categorias/:id', component: CategoriasComponent },
    {path: 'registro', component: RegisterComponent},
    {path: 'login', component: LoginComponent},
    {path: 'logout/:sure', component: LoginComponent},

  { path: 'clientes', component: ClienteComponent, canActivate: [IdentityGuard] },
  { path: 'crear-cliente', component: ClienteNewComponent, canActivate: [IdentityGuard]  },
  { path: 'editar-cliente/:id', component: ClienteEditComponent, canActivate: [IdentityGuard]  },


  { path: 'categorias', component: CategoriaComponent, canActivate: [IdentityGuard] },
  { path: 'crear-categoria', component: CategoriaNewComponent, canActivate: [IdentityGuard]  },
  { path: 'editar-categoria/:id', component: CategoriaEditComponent, canActivate: [IdentityGuard]  },

  { path: 'publicaciones', component: PublicacionComponent, canActivate: [IdentityGuard]  },
  { path: 'crear-publicacion', component: PublicacionNewComponent, canActivate: [IdentityGuard]  },
  { path: 'editar-publicacion/:id', component: PublicacionEditComponent, canActivate: [IdentityGuard]  },

    {path: '**', component: ErrorComponent}
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);
