import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriaService } from '../../../services/categoria.sevice';
import { PublicacionService } from '../../../services/publicacion.sevice';
import { ClienteService } from '../../../services/cliente.service';
import { Publicacion } from '../../../models/publicacion';
import { UserService } from '../../../services/user.service';
import { Horario } from 'src/app/models/horario';

@Component({
  selector: 'app-publicacion-new',
  templateUrl: './publicacion-new.component.html',
  styleUrls: ['./publicacion-new.component.scss'],
  providers: [UserService, CategoriaService, ClienteService, PublicacionService]
})
export class PublicacionNewComponent implements OnInit {
  public page_title: string;
  public identity: any;
  public token: any;
  public publicacion: Publicacion;
  public status: string;
  public categorias: any;
  public clientes: any;
  public horarios: Horario;

  public froala_options: Object = {
    charCounterCount: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
  };


  constructor(
    private _userService: UserService,
    private _router: Router,
    private _categoriaService: CategoriaService,
    private _clienteService: ClienteService,
    private _publicacionService: PublicacionService
    ) {
    this.page_title = 'Crear nueva Publicación';
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.status = '';
    this.publicacion = new Publicacion(1, 1, 1, ' ', ' ', ' ', '', '', '', '', 1, '', '', '', '', '', '', '', '', '', true);
    this.horarios = new Horario('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
  }

  ngOnInit(): void {

    this.getCategorias();
    this.getClientes();
  }

  // Se obtienen todas las categorías
  getCategorias() {
    this._categoriaService.getCategorias().subscribe(
      response => {
        if (response.status == 'success') {
          this.categorias = response.categorias;
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  getClientes() {
    this._clienteService.getClientes().subscribe(
      response => {
        if (response.status == 'success') {
          this.clientes = response.clientes;
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  /* SE ARMAN LOS HORARIOS CARGADOS EN FORMATO JSON */
  setSchedule(horarios:Horario) {
    let schedule = "[ ";

    if (horarios.lunesD1) {
      schedule = this.addDia(
        schedule,
        "lunes",
        horarios.lunesD1,
        horarios.lunesH1,
        horarios.lunesD2,
        horarios.lunesH2
      );
    }
    if (horarios.martesD1) {
      schedule = this.addDia(
        schedule,
        "martes",
        horarios.martesD1,
        horarios.martesH1,
        horarios.martesD2,
        horarios.martesH2
      );
    }
    if (horarios.miercolesD1) {
      schedule = this.addDia(
        schedule,
        "miercoles",
        horarios.miercolesD1,
        horarios.miercolesH1,
        horarios.miercolesD2,
        horarios.miercolesH2
      );
    }
    if (horarios.juevesD1) {
      schedule = this.addDia(
        schedule,
        "jueves",
        horarios.juevesD1,
        horarios.juevesH1,
        horarios.juevesD2,
        horarios.juevesH2
      );
    }
    if (horarios.viernesD1) {
      schedule = this.addDia(
        schedule,
        "viernes",
        horarios.viernesD1,
        horarios.viernesH1,
        horarios.viernesD2,
        horarios.viernesH2
      );
    }
    if (horarios.sabadoD1) {
      schedule = this.addDia(
        schedule,
        "sabado",
        horarios.sabadoD1,
        horarios.sabadoH1,
        horarios.sabadoD2,
        horarios.sabadoH2
      );
    }
    if (horarios.domingoD1) {
      schedule = this.addDia(
        schedule,
        "domingo",
        horarios.domingoD1,
        horarios.domingoH1,
        horarios.domingoD2,
        horarios.domingoH2
      );
    }

    schedule += " ] ";
    return schedule;
  }

  addDia(schedule: string, dia: string, desde1: string, hasta1: string, desde2: string, hasta2: string) {
    if (desde1 && hasta1) {
      if (schedule != "[ ") schedule += ", ";
      schedule += `{ "dia": "${dia}", "horario": [ { "desde": "${desde1}", "hasta": "${hasta1}" } `;
      if (desde2) {
        schedule += `, { "desde": "${desde2}", "hasta": "${hasta2}" } `;
      }
      schedule += " ]  }";
    }
    return schedule;
  }

  onSubmit(form: any) {

    this.publicacion.schedule = this.setSchedule(this.horarios);

    this._publicacionService.create(this.token, this.publicacion).subscribe(
      response => {
        if (response.status == 'success') {
          console.log(response)
          this.status = response.status;
          this._router.navigate(['/publicaciones']);
        } else this.status = 'error';

      },
      error => {
        this.status = 'error';
        console.log(<any>error);
      }
    );

  }

}
