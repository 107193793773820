import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CategoriaService } from '../../services/categoria.sevice';
import { PublicacionService } from '../../services/publicacion.sevice';
import { Categoria } from '../../models/categoria';
import { Publicacion } from '../../models/publicacion';
import { FuncionesService } from '../../services/funciones.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
  providers: [CategoriaService, PublicacionService, FuncionesService]
})
export class CategoriasComponent implements OnInit {
  public page_title: string = "";
  public categoria: Categoria = new Categoria(1, '', '');;
  public status: string = "";
  public publicaciones: Publicacion[] = [];
  public isWait: boolean = true;

  constructor(
    private _categoriaService: CategoriaService,
    private _pubicacionService: PublicacionService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _funcionesService: FuncionesService,
    private meta: Meta
  ) { }

  ngOnInit(): void {

    this.getCategoria();
    this.getPublicaciones();
  }


setMeta(){
  this._funcionesService.setTitle('enLaferrere | ' + this.categoria.categoriaName );

  this.meta.updateTag({ name: 'description', content: this.page_title }, "name='description'");
  this.meta.updateTag({ name: 'keywords', content:  this.categoria.categoriaName +', Laferrere' }, "name='keywords'");
}

  getPublicaciones(){

  this._route.params.subscribe({
    next: (params) => {
      let id = +[params['id']];

      this._pubicacionService.getPublicacionesByCategoria(id).subscribe(
    response => {
      if (response.status == 'success') {
        //this.publicaciones = response.publicaciones;
        for (let index = 0; index < response.publicaciones.length; index++) {
         const element = response.publicaciones[index];
          element.schedule = JSON.parse(response.publicaciones[index].schedule);
          this.publicaciones.push(element);
       }

      }
        this.isWait = false;
    },
    error => {
      console.log(error);
      this.isWait = false;
    }
  )
    },
    complete: () => { this.isWait = false;},
    error: (error) => { console.log(error); }
  });
}

  getCategoria() {

    this._route.params.subscribe({
      next: (params) => {
        let id = +[params['id']];

        this._categoriaService.getCategoria(id).subscribe(
          response => {
            console.log(response)
            if (response.status == 'success') {
              this.page_title = response.categoria.categoriaName + ' en Laferrere';
              this.categoria = response.categoria;

              this.setMeta();

            }
          },
          error => {
            console.log(error);
          }
        )
      },
      complete: () => { },
      error: (error) => { console.log(error); }
    });

  }

}
