<div class="col-md-12 mt-3">
    <h1>{{page_title}}</h1>


    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        Datos grabados correctamente.
    </div>
    <div class="alert alert-danger col-md-5 mt-3" *ngIf="status == 'error'">
        Error al guardar los datos, por favor inténtalo más tarde.
    </div>

    <form class="col-md-5 ml-0 pl-0" #categoryForm="ngForm" (ngSubmit)="onSubmit(categoryForm)">
        <div class="form-group mb-2">
            <label for="name">Nombre</label>
            <input type="text" name="name" class="form-control" #name="ngModel" [(ngModel)]="categoria.categoriaName" required>
            <small *ngIf="!name.valid && name.touched" class="invalid-feedback
        d-block">
        El nombre no es válido
      </small>
        </div>

        <div class="form-group mb-2">
            <label for="iconImage">Imagen</label>
            <input type="text" name="iconImage" class="form-control" #iconImage="ngModel" [(ngModel)]="categoria.iconImage">
            <small *ngIf="!iconImage.valid && iconImage.touched" class="invalid-feedback d-block">
        La imagen no es válida
      </small>
        </div>


        <input type="submit" value="Guardar" class="btn btn-success mt-2" [disabled]="categoryForm.invalid">
    </form>
</div>
