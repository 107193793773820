import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
    export class FuncionesService{

        constructor(    private titleService: Title){}

        goToLocation(location:string): void {
            console.log(location)
            if (location != "" && location != undefined) {
            window.open('https://www.google.com/maps/place/' +
                    location, "_blank");
        }
        }

        goToTel(tel:string): void {
            if (tel != "" && tel != undefined)
                window.open('tel:' + tel);

        }

        setTitle(newTitle: string) {
            this.titleService.setTitle(newTitle);
        }
     }


