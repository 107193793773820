<div class="col-md-12 mt-3">
    <h1>{{page_title}}</h1>

    <div class="alert alert-success col-md-5 mt-3" *ngIf="status == 'success'">
        Datos grabados correctamente.
    </div>
    <div class="alert alert-danger col-md-5 mt-3" *ngIf="status == 'error'">
        Error al guardar los datos, por favor inténtalo más tarde.
    </div>

    <form class="col-md-12 ml-0 pl-0" #clienForm="ngForm" (ngSubmit)="onSubmit(clienForm)">

        <div class="row">
            <div class="form-group mb-2 col-sm">
                <label for="name">Nombre</label>
                <input type="text" name="name" class="form-control" #name="ngModel" [(ngModel)]="cliente.name" required>
                <small *ngIf="!name.valid && name.touched" class="invalid-feedback
          d-block">
          El nombre no es válido
        </small>
            </div>

            <div class="form-group mb-2 col-sm">
                <label for="surname">Apellido</label>
                <input type="text" name="surname" class="form-control" #surname="ngModel" [(ngModel)]="cliente.surname" required>
                <small *ngIf="!surname.valid && surname.touched" class="invalid-feedback
          d-block">
          El apellido no es válido
        </small>
            </div>

            <div class="form-group mb-2 col-sm">
                <label for="razon_social">Razón social</label>
                <select type="text" name="razon_social" class="form-control" #razon_social="ngModel" [(ngModel)]="cliente.razon_social">
          <option value=""> </option>
          <option value="IVA Responsable Inscripto">
            IVA Responsable Inscripto
          </option>
          <option value="IVA Sujeto Exento">
            IVA Sujeto Exento
          </option>
          <option value="Consumidor Final">
            Consumidor Final
          </option>
          <option value="Responsable Monotributo">
            Responsable Monotributo
          </option>
          <option value="Sujeto No Categorizado">
            Sujeto No Categorizado
          </option>

        </select>


                <small *ngIf="!razon_social.valid && razon_social.touched" class="invalid-feedback d-block">
          El razón social no es válida
        </small>
            </div>
        </div>

        <div class="row">
            <div class="form-group mb-2 col-sm">
                <label for="email">Email</label>
                <input type="email" name="email" class="form-control" #email="ngModel" [(ngModel)]="cliente.email">
                <small *ngIf="!email.valid && email.touched" class="invalid-feedback
          d-block">
          El email no es válido
        </small>
            </div>

            <div class="form-group mb-2 col-sm">
                <label for="phone">Teléfono</label>
                <input type="text" name="phone" class="form-control" #phone="ngModel" [(ngModel)]="cliente.phone">
                <small *ngIf="!phone.valid && phone.touched" class="invalid-feedback
          d-block">
          El teléfono no es válido
        </small>
            </div>

            <div class="form-group mb-2 col-sm">
                <label for="direction">Dirección</label>
                <input type="text" name="direction" class="form-control" #direction="ngModel" [(ngModel)]="cliente.direction">
                <small *ngIf="!direction.valid && direction.touched" class="invalid-feedback d-block">
          El dirección no es válida
        </small>
            </div>
        </div>

        <div class="row">

            <div class="form-group mb-2 col-sm">
                <label for="document_type">Tipo de documento</label>
                <select type="text" name="document_type" class="form-control" #document_type="ngModel" [(ngModel)]="cliente.document_type">
          <option value=""> </option>
          <option value="CUIT">
            CUIT
          </option>
          <option value="CUIL">
            CUIL
          </option>
          <option value="DNI">
            DNI
          </option>
          <option value="LE">
            LE
          </option>
          <option value="LC">
            LC
          </option>

        </select>


                <small *ngIf="!document_type.valid && document_type.touched" class="invalid-feedback d-block">
          El tipo de documento no es válido
        </small>
            </div>

            <div class="form-group mb-2 col-sm">
                <label for="document_number">Número de documento</label>
                <input type="text" name="document_number" class="form-control" #document_number="ngModel" [(ngModel)]="cliente.document_number">
                <small *ngIf="!document_number.valid && document_number.touched" class="invalid-feedback
          d-block">
          El número de documento no es válido
        </small>
            </div>

            <div class="form-group mb-2 col-sm">
                <label for="description">Descripción</label>
                <textarea name="description" class="form-control" #description="ngModel" [(ngModel)]="cliente.description"></textarea>
                <small *ngIf="!description.valid && description.touched" class="invalid-feedback
          d-block">
          La descripción no es válida
        </small>
            </div>

        </div>

        <input type="submit" value="Guardar" class="btn btn-success mt-2" [disabled]="clienForm.invalid">
    </form>
</div>
