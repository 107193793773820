<div class="card">
    <div class="card-header">
        <h2 class="card-title text-center h4">{{ publicacion.title }}</h2>
        <img *ngIf="categoria.iconImage" [src]="'assets/images/' + categoria.iconImage +'.png'" />
    </div>

    <div class="card-body">
        <h5 class="card-content text-center">{{ publicacion.content }}</h5>
        <hr>
        <!--    Datos de contacto   -->
        <dl class="card-text">
            <div class="card-text-item" *ngIf="publicacion.phone">
                <dt class="text-center">
                Teléfono
              </dt>

                <dd class="text-center">
                    {{ publicacion.phone }}
                </dd>
                <dd class="text-center">
                    <button (click)="goToTel(publicacion.phone)" class="btn btn-primary position-relative">
                      LLamar <i class="fa fa-mobile"></i>
                    </button>

                </dd>
            </div>

            <div class="card-text-item" *ngIf="publicacion.phone2">
                <dt class="text-center">
                Teléfono
              </dt>

                <dd class="text-center">
                    {{ publicacion.phone2 }}
                </dd>
                <dd class="text-center">
                    <button (click)="goToTel(publicacion.phone2)" class="btn btn-primary position-relative">
                      LLamar <i class="fa fa-mobile"></i>
                    </button>

                </dd>
            </div>

            <!-- Ubicación -->
            <div class="card-text-item">
                <dt class="text-center" *ngIf="publicacion.location">
                Dirección
              </dt>


                <dd class="text-center" *ngIf="publicacion.location">
                    {{ publicacion.location }}
                </dd>
                <dd class="text-center" *ngIf="publicacion.location || publicacion.map">
                    <button (click)="goToDireccion(publicacion.map ? publicacion.map : publicacion.location, publicacion.map)" class="btn btn-primary position-relative">
                      Cómo llegar <i class="fa fa-map-marker"></i>
                    </button>
                </dd>


            </div>
        </dl>

    </div>


    <!-- Redes sociales -->
    <div class="card-body" *ngIf="
        publicacion.whatsapp ||
        publicacion.instagram ||
        publicacion.facebook ||
        publicacion.linkedin ||
        publicacion.twitter ||
        publicacion.youtube ||
        publicacion.telegram ||
        publicacion.pinterest
      ">
        <hr>
        <h6 class="card-subtitle text-center">Redes Sociales</h6>

        <dl class="row card-text redes-sociales">
            <a [href]="
            'https://api.whatsapp.com/send?phone=' +
            publicacion.whatsapp +
            '&text=Mensaje enviado desde enLaferrere.com.ar: '
          " target="_blank" *ngIf="publicacion.whatsapp" type="button" class="btn btn-primary position-relative" data-mdb-ripple-unbound="true" style="background-color: #1bd741 !important" role="button">
          Whatsapp <i class="fab fa-whatsapp"></i>
        </a>

            <!-- Instagram -->
            <a [href]="'https://www.instagram.com/' + publicacion.instagram" target="_blank" *ngIf="publicacion.instagram" type="button" class="btn btn-primary position-relative" data-mdb-ripple-unbound="true" style="background-color: #ac2bac">
          Instagram <i class="fab fa-instagram"></i>
        </a>

            <!-- Facebook -->
            <a [href]="'https://www.facebook.com/' + publicacion.facebook" target="_blank" *ngIf="publicacion.facebook" type="button" class="btn btn-primary position-relative" data-mdb-ripple-unbound="true" style="background-color: #3b5998 !important" role="button">
          Facebook <i class="fab fa-facebook-f"></i>
        </a>

            <a [href]="'https://www.linkedin.com/in/' + publicacion.linkedin" target="_blank" *ngIf="publicacion.linkedin" type="button" class="btn btn-primary position-relative" data-mdb-ripple-unbound="true" style="background-color: #3467c2 !important" role="button">
          Linkedin <i class="fab fa-linkedin"></i>
        </a>

            <!-- Twitter -->
            <a [href]="'https://twitter.com/' + publicacion.twitter" target="_blank" *ngIf="publicacion.twitter" type="button" class="btn btn-primary position-relative" data-mdb-ripple-unbound="true" style="background-color: #55acee !important">
          Twitter <i class="fab fa-twitter ms-1"></i>
        </a>

            <a [href]="'https://youtube.com/channel/' + publicacion.youtube" target="_blank" *ngIf="publicacion.youtube" type="button" class="btn btn-primary position-relative" data-mdb-ripple-unbound="true" style="background-color: #c4302b !important">
          Youtube <i class="fab fa-youtube ms-1"></i>
        </a>

            <a [href]="'https://msng.link/o/?' + publicacion.telegram+'=tg&text=Mensaje enviado desde enLaferrere.com.ar: '" target="_blank" *ngIf="publicacion.telegram" type="button" class="btn btn-primary position-relative" data-mdb-ripple-unbound="true" style="background-color: #0088cc !important">
          Telegram <i class="fab fa-telegram ms-1"></i>
        </a>
        </dl>
    </div>


    <!--   HORARIOS -->
    <div class="card-footer text-muted" *ngIf="horarios && horarios[0]">
        <h6 class="text-center card-subtitle">Horarios</h6>
        <ul class="lista-horarios">
            <li class="item-horarios" *ngFor="let horario of horarios">
                <div>{{ horario.dia }}</div>
                <div>
                    {{ horario.horario[0].desde }}-{{ horario.horario[0].hasta }}
                </div>
                <div *ngIf="horario.horario[1]">
                    {{ horario.horario[1].desde }}-{{ horario.horario[1].hasta }}
                </div>
            </li>
        </ul>
    </div>
</div>
