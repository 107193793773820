import { Component, OnInit, Input } from '@angular/core';
import { FuncionesService } from '../../services/funciones.service';

@Component({
  selector: 'tarjeta-emergencias',
  templateUrl: './tarjeta.emergencias.component.html',
  styleUrls: ['./tarjeta.emergencias.component.scss'],
  providers: [FuncionesService]
})
export class TarjetaEmergenciasComponent implements OnInit {
    @Input() emergencias: any;
    @Input() categoria: any;
    @Input() imagen: any;

  constructor(private _funcionesService: FuncionesService) { }

  ngOnInit(): void {
  }

  goToDireccion(location:string, map:string = ""): void {
    if(map == "")
      this._funcionesService.goToLocation(location + '+Gregorio+de+Laferrere+Provincia+de+Buenos+Aires');
    else
        this._funcionesService.goToLocation(location);
  }


  goToTel(tel:string): void {
    this._funcionesService.goToTel(tel);
  }


}
