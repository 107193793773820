import { Component, OnInit } from '@angular/core';
import { ClienteService } from '../../../services/cliente.service';
import { Cliente } from '../../../models/cliente';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss'],
  providers: [ClienteService, UserService]
})
export class ClienteComponent implements OnInit {
  clientes: Cliente[] = [];
  public token: string;

  constructor(
    private _clienteService: ClienteService,
    private _userService: UserService) {
    this.token = this._userService.getToken();
   }

  ngOnInit(): void {
    this.getClientes();
  }

  getClientes() {
    this._clienteService.getClientes().subscribe(
      response => {
        if (response.status == 'success') {
          this.clientes = response.clientes;


        }
      },
      error => {
        console.log(error);
      }
    )
  }

  deleteCliente(id: number) {
    if (confirm("Se eliminará el cliente " + id))
    this._clienteService.delete(this.token, id).subscribe(
      response => {
        this.getClientes();

      },
      error => {
        console.log(error);
      }
    )
  }


}
