import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { routing, appRoutingProviders } from './app.routing';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { EmergenciasComponent } from './components/emergencias/emergencias.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { ErrorComponent } from './components/error/error.component';
import { UtilesComponent } from './components/utiles/utiles.component';
import { FarmaciasComponent } from './components/farmacias/farmacias.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TarjetaEmergenciasComponent } from './components/tarjeta.emergencias/tarjeta.emergencias.component';
import { RegisterComponent } from './components/register/register.component';
import { LoginComponent } from './components/login/login.component';
import { CategoriaNewComponent } from './components/admin/categoria-new/categoria-new.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CategoriaComponent } from './components/admin/categoria/categoria.component';
import { PublicacionNewComponent } from './components/admin/publicacion-new/publicacion-new.component';
import { ClienteNewComponent } from './components/admin/cliente-new/cliente-new.component';
import { ClienteComponent } from './components/admin/cliente/cliente.component';
import { ClienteEditComponent } from './components/admin/cliente-edit/cliente-edit.component';
import { DeleteConfirmationDailogComponent } from './components/delete-confirmation-dailog/delete-confirmation-dailog.component';
import { CategoriaEditComponent } from './components/admin/categoria-edit/categoria-edit.component';
import { PublicacionComponent } from './components/admin/publicacion/publicacion.component';
import { PublicacionEditComponent } from './components/admin/publicacion-edit/publicacion-edit.component';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { SearchPipe } from './pipes/search.pipe';
import { TarjetaPublicacionComponent } from './components/tarjeta-publicacion/tarjeta-publicacion.component';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { IdentityGuard } from './services/identity.guard';
import { UserService } from './services/user.service';


@NgModule({
  declarations: [
    AppComponent,
    EmergenciasComponent,
    InicioComponent,
    ErrorComponent,
    UtilesComponent,
    FarmaciasComponent,
    TarjetaEmergenciasComponent,
    RegisterComponent,
    LoginComponent,
    CategoriaNewComponent,
    CarouselComponent,
    CategoriaComponent,
    PublicacionNewComponent,
    ClienteNewComponent,
    ClienteComponent,
    ClienteEditComponent,
    DeleteConfirmationDailogComponent,
    CategoriaEditComponent,
    PublicacionComponent,
    PublicacionEditComponent,
    BuscadorComponent,
    SearchPipe,
    TarjetaPublicacionComponent,
    CategoriasComponent
  ],
  imports: [
    IvyCarouselModule,
    BrowserModule,
    routing,
    MatNativeDateModule,
    MatCardModule,
    MatDatepickerModule,
    FormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    //NoopAnimationsModule
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }, Title, IdentityGuard,UserService],
  bootstrap: [AppComponent]
})
export class AppModule { }
