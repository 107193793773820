import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cliente } from '../models/cliente';
import { global } from './global';

@Injectable()
export class ClienteService {
  public url: string;
  public token: any;
  public identity: any;

  constructor(public _http: HttpClient

  ) {
    this.url = global.url;
    this.token = null;
    this.identity = null;
  }


  create(token: string, cliente: Cliente): Observable<any> {

    let json = JSON.stringify(cliente);
    let params = 'json=' + json;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.post(this.url + 'client', params, { headers: headers });

  }

  getClientes(): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'client', { headers: headers });
  }

  // Se obtienen los datos de 1 cliente
  getCliente(id: number): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.get(this.url + 'client/' + id, { headers: headers });
  }

  update(token: string, cliente: Cliente, id: number): Observable<any> {

    let json = JSON.stringify(cliente);
    let params = 'json=' + json;
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.put(this.url + 'client/'+id, params, { headers: headers });
  }

  delete(token: string, id: number){
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);
    return this._http.delete(this.url + 'client/' + id, { headers: headers })
  }


}
