<h1 class="text-center fs-3 mt-2 mb-3">{{page_title}}</h1>

<div class="calendario">
    <div>
        <mat-card class="calendar-card">
        <mat-calendar (selectedChange)="fechaSeleccionada($event)"></mat-calendar>
        </mat-card>
        <p class="text-center">
            Fecha seleccionada: {{selected | date:'dd-MM-yyyy'}}
        </p>
    </div>

    <ul class="card border-warning bg-light mb-3 card-farmacias">

        <li *ngFor="let farmacia of farmacias" 
        class="list-group-item lista-farmacias">
          <div>
            <div class="h6">{{ farmacia.nombre }}</div>
            <div>{{ farmacia.direccion }}</div>
          </div>

          <button (click)="goToDireccion(farmacia.direccion)"
            class="btn btn-primary position-relative"
          > Cómo llegar <i class="fa fa-map-marker"></i>
        </button>
        </li>
      </ul>
   
</div>



    <p class="text-center leyenda">
      <b>IMPORTANTE:</b> Los turnos comienzan a las 8:30 hs. del día asignado y
      finalizan a las 8:30 hs. del día siguiente.
    </p>


