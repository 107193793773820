import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-confirmation-dailog',
  templateUrl: './delete-confirmation-dailog.component.html',
  styleUrls: ['./delete-confirmation-dailog.component.scss']
})
export class DeleteConfirmationDailogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
