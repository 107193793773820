export class Horario {
  constructor(
    public lunesD1: string,
    public lunesH1: string,
    public lunesD2: string,
    public lunesH2: string,
    public martesD1: string,
    public martesH1: string,
    public martesD2: string,
    public martesH2: string,
    public miercolesD1: string,
    public miercolesH1: string,
    public miercolesD2: string,
    public miercolesH2: string,
    public juevesD1: string,
    public juevesH1: string,
    public juevesD2: string,
    public juevesH2: string,
    public viernesD1: string,
    public viernesH1: string,
    public viernesD2: string,
    public viernesH2: string,
    public sabadoD1: string,
    public sabadoH1: string,
    public sabadoD2: string,
    public sabadoH2: string,
    public domingoD1: string,
    public domingoH1: string,
    public domingoD2: string,
    public domingoH2: string
  ) { }
}
