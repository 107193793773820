import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { CategoriaService } from '../../../services/categoria.sevice';
import { Categoria } from '../../../models/categoria';

@Component({
  selector: 'app-categoria-edit',
  templateUrl: '../categoria-new/categoria-new.component.html',
  styleUrls: ['../categoria-new/categoria-new.component.scss'],
  providers: [UserService, CategoriaService]
})
export class CategoriaEditComponent implements OnInit {

  public page_title: string;
  public identity: any;
  public token: any;
  public categoria: Categoria;
  public status: string;

  constructor(
    private _userService: UserService,
    private _categoriaService: CategoriaService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {
    this.page_title = 'Editar categoría';
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    this.categoria = new Categoria(1, '', '');
    this.status = '';
  }

  ngOnInit(): void {
    this.getCategoria();
  }


  onSubmit(form: any) {
    this._categoriaService.update(this.token, this.categoria, this.categoria.id).subscribe(
      response => {
        if (response.status == 'success') {
          this.status = response.status;
          this.categoria = response.categoria;
          this._router.navigate(['/categorias']);
        } else this.status = 'error';

      },
      error => {
        this.status = 'error';
        console.log(<any>error);
      }
    );

  }

  getCategoria() {

    this._route.params.subscribe({
      next: (params) => {
        let id = +[params['id']];

        this._categoriaService.getCategoria(id).subscribe(
          response => {
            console.log(response)
            if (response.status == 'success') {
              this.categoria = response.categoria;

            }
          },
          error => {
            console.log(error);
          }
        )
      },
      complete: () => { },
      error: (error) => { console.log(error); }
    });

  }

}

