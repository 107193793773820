import { Component, OnInit, Input } from '@angular/core';
import { Categoria } from 'src/app/models/categoria';
import { Horario } from 'src/app/models/horario';
import { Publicacion } from 'src/app/models/publicacion';
import { FuncionesService } from '../../services/funciones.service';

@Component({
  selector: 'app-tarjeta-publicacion',
  templateUrl: './tarjeta-publicacion.component.html',
  styleUrls: ['./tarjeta-publicacion.component.scss'],
  providers: [FuncionesService]
})
export class TarjetaPublicacionComponent implements OnInit {
  @Input() publicacion: Publicacion;
  @Input() categoria: Categoria;
  @Input() horarios: any;

  constructor(private _funcionesService: FuncionesService) {
    this.publicacion = new Publicacion(1, 1, 1, ' ', ' ', ' ', '', '', '', '', 1, '', '', '', '', '', '', '', '', '', true);
    this.categoria = new Categoria(1, '', '');
   }

  ngOnInit(): void {
  }

  goToDireccion(location: string, map: string = ""): void {
    if (map == "")
      this._funcionesService.goToLocation(location + '+Gregorio+de+Laferrere+Provincia+de+Buenos+Aires');
    else
      this._funcionesService.goToLocation(location);
  }


  goToTel(tel: string): void {
    this._funcionesService.goToTel(tel);
  }


}
